<template>
    <div id="dashboard-content">
      <h4><b>Pengajuan ISBN</b></h4>
      <div v-if="$store.state.messageStatusISBN" class="alert alert-success" role="alert">
        Berhasil ditambahkan. <i class="fa fa-check"></i>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <small class="text-muted">Silahkan isi data dengan lengkap</small>
      <div class="mt-4 card border-0 shadow-sm p-4">
        <form @submit.prevent="isbnAdd()" enctype="multipart/form-data" accept-charset="utf-8">
          <input type="hidden" name="csrf_test_name" value="44c8529798bd8316b2483295c17206d9" /><input type="hidden"
            name="status" value="draft" />
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Judul</label>
                <input v-model="isbn.judul" type="text" class="form-control" placeholder="Judul" required="required" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Kepengarangan</label>
                <input v-model="isbn.kepengarangan" type="text" class="form-control" placeholder="Kepengarangan"
                  required="required" />
                <small class="text-muted">penulis/pengarang/penyusun ; penerjemah/alihbahasa ; editor/penyunting,
                  dll</small>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label>Sinopsis / Subjek</label>
                <textarea v-model="isbn.sinopsis" maxlength="1000" placeholder="Sinopsis / Subjek" rows="5" class="form-control" required></textarea>
                <small class="text-muted">Maksimal karakter {{ isbn.sinopsis.length }}/1000</small>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <!-- <div class="col-md-12">
              <div class="form-group">
                <label>Distributor</label>
                <input v-model="isbn.distributor" type="text" class="form-control" placeholder="Distributor" required="required" />
                <div class="help-block with-errors"></div>
              </div>
            </div> -->
            <div class="col-md-6">
              <div class="form-group">
                <label>Edisi</label>
                <input v-model="isbn.edisi" type="number" class="form-control" placeholder="Edisi" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Seri</label>
                <input v-model="isbn.seri" type="text" class="form-control" placeholder="Seri" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Tahun Terbit</label>
                <input v-model="isbn.tahun_terbit" type="number" class="form-control" placeholder="Tahun Terbit"
                  required="required" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Jumlah Halaman</label>
                <input v-model="isbn.jumlah_halaman" type="text" class="form-control" placeholder="Jumlah Halaman"
                  required="required" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Tinggi Buku</label>
                <input v-model="isbn.tinggi_buku" type="number" class="form-control" placeholder="Tinggi Buku"
                  required="required" />
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Kelompok Pembaca</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kelompok_pembaca" value="Anak" type="radio" id="kelompokPembaca1"
                      name="kelompokPembaca" class="custom-control-input">
                    <label class="custom-control-label" for="kelompokPembaca1">Anak</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kelompok_pembaca" value="Dewasa" type="radio" id="kelompokPembaca2"
                      name="kelompokPembaca" class="custom-control-input">
                    <label class="custom-control-label" for="kelompokPembaca2">Dewasa</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kelompok_pembaca" value="Semua Umur" type="radio" id="kelompokPembaca3"
                      name="kelompokPembaca" class="custom-control-input">
                    <label class="custom-control-label" for="kelompokPembaca3">Semua Umur</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Jenis Pustaka</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.jenis_pustaka" value="Fiksi" type="radio" id="jenisPustaka1" name="jenisPustaka"
                      class="custom-control-input">
                    <label class="custom-control-label" for="jenisPustaka1">Fiksi</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.jenis_pustaka" value="Non Fiksi" type="radio" id="jenisPustaka2"
                      name="jenisPustaka" class="custom-control-input">
                    <label class="custom-control-label" for="jenisPustaka2">Non Fiksi</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Kategori Jenis</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kategori_jenis" value="Terjemahan" type="radio" id="kategoriJenis1"
                      name="kategoriJenis" class="custom-control-input">
                    <label class="custom-control-label" for="kategoriJenis1">Terjemahan</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kategori_jenis" value="Non Terjemahan" type="radio" id="kategoriJenis2"
                      name="kategoriJenis" class="custom-control-input">
                    <label class="custom-control-label" for="kategoriJenis2">Non Terjemahan</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Terbitan</div>
                <div class="col-md-8">
                  <div class="form-check">
                    <input v-model="isbn.terbitan" disabled value="Pemerintah" type="checkbox" id="terbitan1"
                      name="terbitan" class="form-check-input">
                    <label class="form-check-label" for="terbitan1">Pemerintah</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Media</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="Buku" type="radio" id="media1" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media1">Buku</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="PDF" type="radio" id="media2" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media2">PDF</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="Epub" type="radio" id="media3" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media3">Epub</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="Audio book CD" type="radio" id="media4" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media4">Audio Book (CD)</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="Audio book DVD" type="radio" id="media5" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media5">Audio Book (DVD)</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.media" value="Video" type="radio" id="media6" name="media" class="custom-control-input">
                    <label class="custom-control-label" for="media6">Video</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">ISBN Susulan</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.isbn_susulan" value="Ya" type="radio" id="isbnSusulan1" name="isbnSusulan"
                      class="custom-control-input">
                    <label class="custom-control-label" for="isbnSusulan1">Ya</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.isbn_susulan" value="Tidak" type="radio" id="isbnSusulan2" name="isbnSusulan"
                      class="custom-control-input">
                    <label class="custom-control-label" for="isbnSusulan2">Tidak</label>
                  </div>
                  <!-- <div v-show="isbn.isbn_susulan == 'Tidak'" class="form-group my-2">
                    <input type="text" placeholder="Masukan jilid lengkap" class="form-control">
                  </div> -->
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Pengajuan KDT</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.pengajuan_kdt" value="Ya" type="radio" id="pengajuanKDT1" name="pengajuanKDT"
                      class="custom-control-input">
                    <label class="custom-control-label" for="pengajuanKDT1">Ya</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.pengajuan_kdt" value="Tidak" type="radio" id="pengajuanKDT2" name="pengajuanKDT"
                      class="custom-control-input">
                    <label class="custom-control-label" for="pengajuanKDT2">Tidak</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Penelitian</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.penelitian" value="Ya" type="radio" id="penelitian1" name="penelitian" class="custom-control-input">
                    <label class="custom-control-label" for="penelitian1">Ya</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.penelitian" value="Tidak" type="radio" id="penelitian2" name="penelitian" class="custom-control-input">
                    <label class="custom-control-label" for="penelitian2">Tidak</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-2">
              <div class="row">
                <div class="col-md-4">Kategori Buku</div>
                <div class="col-md-8">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kategori_buku" value="Lepas" type="radio" id="kategoriBuku1" name="kategoriBuku"
                      class="custom-control-input">
                    <label class="custom-control-label" for="kategoriBuku1">Lepas</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                    <input v-model="isbn.kategori_buku" value="Jilid" type="radio" id="kategoriBuku2" name="kategoriBuku"
                      class="custom-control-input">
                    <label class="custom-control-label" for="kategoriBuku2">Jilid</label>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-md-12 mt-2">
              <div class="form-group">
                <label>Cover</label>
                <input type="hidden" class="form-control mb-2" placeholder="Preview URL" v-model="isbn.cover" />
                <small v-if="messageError.cover !== ''" class="text-danger d-block">{{ messageError.cover }}</small>
                <input @change="selectFile($event, 'cover')" type="file" accept="image/*" name="avatar" id="avatar" required class="form-control" />
                <a v-if="isbn.cover" :href="isbn.cover" target="_blank" class="my-2 d-block text-dark small" style="cursor: pointer"><i class="fa fa-image"></i> Lihat Cover</a>
                <img v-else class="my-2 img-fluid w-25" src="https://via.placeholder.com/240x300?text=Kover%20Buku" />
                <div>
                  <div class="row">
                    <div class="col-md-4">
                      <button v-if="!loading.cover && !isbn.cover" @click="uploadingFile('cover')" type="button" class="btn btn-primary btn-block">
                        Upload
                      </button>
                      <button type="button" v-if="loading.cover" class="btn btn-primary btn-block">
                        <span class="spinner-border spinner-border-sm"></span>
                        Sedang upload..
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <!--
            <div class="col-md-12 my-2">
              <div class="row d-flex align-items-center">
                <div class="col-md-7">
                  <div class="form-group">
                    <label>Buku Dummy (PDF) Maks 1,5 MB</label>
                    <input v-model="isbn.dummy" type="hidden" class="form-control" />
                    <small v-if="messageError.dummy !== ''" class="text-danger d-block">{{ messageError.dummy }}</small>
                    <input @change="selectFile($event, 'dummy')" type="file" class="form-control" required="required" />
                    <div class="help-block with-errors"></div>
                  </div>
                  <div v-show="message.dummy" class="mt-2 alert alert-success">
                    {{ message.dummy }}
                  </div>
                  <button @click="uploadingFile('dummy')" v-if="!loading.dummy && !isbn.dummy" type="button" class="btn btn-primary btn-block">
                    Upload
                  </button>
                  <button type="button" v-if="loading.dummy" class="btn btn-primary btn-block">
                    <span class="spinner-border spinner-border-sm"></span>
                    Sedang diproses
                  </button>
                </div>
              </div>
            </div>
            -->
  
            <div class="col-md-12 my-2">
              <div class="row d-flex align-items-center">
                <div class="col-md-7">
                  <div class="form-group">
                    <label>SKK & Surat Pengantar (PDF) Maks 1,5 MB</label>
                    <input v-model="isbn.lampiran" type="hidden" class="form-control" />
                    <small v-if="messageError.lampiran !== ''" class="text-danger d-block">{{ messageError.lampiran }}</small>
                    <input @change="selectFile($event, 'lampiran')" type="file" class="form-control" required="required" />
                    <div class="help-block with-errors"></div>
                  </div>
                  <div v-show="message.lampiran" class="mt-2 alert alert-success">
                    {{ message.lampiran }}
                  </div>
                  <button @click="uploadingFile('lampiran')" v-if="!loading.lampiran && !isbn.lampiran" type="button" class="btn btn-primary btn-block">
                    Upload
                  </button>
                  <button type="button" v-if="loading.lampiran" class="btn btn-primary btn-block">
                    <span class="spinner-border spinner-border-sm"></span>
                    Sedang diproses
                  </button>
                </div>
              </div>
            </div>
  
            <div class="col-md-12 my-2">
              <div class="row d-flex align-items-center">
                <div class="col-md-7">
                  <div class="form-group">
                    <label>Prelim (PDF) Maks 1,5 MB</label>
                    <input v-model="isbn.prelim" type="hidden" class="form-control" />
                    <small v-if="messageError.prelim !== ''" class="text-danger d-block">{{ messageError.prelim }}</small>
                    <input @change="selectFile($event, 'prelim')" type="file" class="form-control" required="required" />
                    <div class="help-block with-errors"></div>
                  </div>
                  <div v-show="message.prelim" class="mt-2 alert alert-success">
                    {{ message.prelim }}
                  </div>
                  <button @click="uploadingFile('prelim')" v-if="!loading.prelim && !isbn.prelim" type="button" class="btn btn-primary btn-block">
                    Upload
                  </button>
                  <button type="button" v-if="loading.prelim" class="btn btn-primary btn-block">
                    <span class="spinner-border spinner-border-sm"></span>
                    Sedang diproses
                  </button>
                </div>
              </div>
            </div>
  
          </div>
          <div v-show="failedRequest" class="alert alert-warning alert-dismissible fade show" role="alert">
            Terjadi kesalahan teknis
            <button @click="failedRequest = false" type="button" class="close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="text-right">
            <button v-if="$store.state.loadPage" class="btn btn-primary">
              <span class="spinner-border spinner-border-sm"></span>
              Sedang diproses..
            </button>
            <button v-else type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="successModal" tabindex="-1" role="dialog" aria-labelledby="successModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header p-0 border-0">
              <button class="btn  btn-sm" data-dismiss="modal"><i class="fa fa-x"></i></button>
            </div>
            <div class="modal-body text-center">
              <i class="fa fa-circle-check text-success h1"></i>
              <h4>Pengajuan ISBN berhasil dibuat</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  export default {
    name: "ISBN Add",
    data() {
      return {
        failedRequest: false,
        isbn: {
          judul: "",
          kepengarangan: "",
          sinopsis: "",
          distributor: "",
          edisi: "",
          seri: "",
          cover: "",
          tahun_terbit: "2024",
          jumlah_halaman: "",
          tinggi_buku: "",
          kelompok_pembaca: "Anak",
          jenis_pustaka: "Fiksi",
          kategori_jenis: "Non Terjemahan",
          terbitan: "Pemerintah",
          media: [],
          isbn_susulan: "Ya",
          pengajuan_kdt: "Ya",
          penelitian: "Ya",
          kategori_buku: "Lepas",
          lampiran: "",
          prelim: "",
          dummy: "",
          mode: "ISBN",
        },
        loading: {
          lampiran: false,
          prelim: false,
          cover: false,
          dummy: false,
        },
        file: {
          lampiran: null,
          prelim: null,
          cover: null,
          dummy: null,
        },
        messageStatus: {
          lampiran: '',
          prelim: '',
          dummy: '',
        },
        message: {
          lampiran: '',
          prelim: '',
          dummy: '',
        },
        messageError: {
          lampiran: '',
          prelim: '',
          cover: '',
          dummy: '',
        },
      };
    },
    computed: {
      previewImage() {
        if (this.isbn.cover != "") {
          return this.isbn.cover;
        } else {
          return "https://via.placeholder.com/240x300?text=Kover%20Buku"
        }
      },
    },
    methods: {
      ...mapActions(["addNewISBN", "uploadFile", "uploadGoogleDrive"]),
      selectFile(e, state) {
        this.isbn[state] = '';
        this.message[state] = '';
        const file = e.target.files[0];
        this.file[state] = file;
      },
      uploadingFile(state) {
        if (this.file[state] === null) {
          this.messageError[state] = `File harus diisi`
        } else {
          if (this.file[state].size >= 1500000) {
            this.messageError[state] = "Ukuran file terlalu besar, maksimal 1,5 mb";
          } else {
            const uploader = state == 'cover' ? this.uploadGoogleDrive : this.uploadFile;
            this.loading[state] = true;
            this.messageError[state] = "";
            uploader(this.file[state])
              .then((res) => {
                if (res.status === "success" || res.status == 200) {
                  const fileUploaded = state == 'cover' ? res.data.url : res.file
                  this.isbn[state] = fileUploaded;
                  this.messageStatus[state] = true;
                  state !== 'cover' ? this.message[state] = "Berhasil diunggah, selanjutnya silahkan klik tombol simpan" : null;
                  this.file[state] = null;
                } else {
                  this.messageError[state] = res.message;
                }
              })
              .catch((err) => {
                this.messageError[state] = "File gagal diupload";
                this.$store.state.loadUploadFile = false;
                console.log(err);
              })
              .finally(() => this.loading[state] = false);
          }
        }
      },
      isbnAdd() {
        if (this.isbn.cover === "") {
          this.messageError.cover = "Silahkan upload file cover terlebih dahulu";
        } else if(this.isbn.lampiran === "") {
          this.messageError.lampiran = "Silahkan upload file lampiran terlebih dahulu";
        } else if(this.isbn.prelim === "") {
          this.messageError.prelim = "Silahkan upload file prelim terlebih dahulu";
        } else {
          this.addNewISBN(this.isbn)
          .then(() => {
            this.$router.push("/user/pengajuan-isbn");
          })
          .catch(() => {
            this.failedRequest = true;
          });
        }
      },
    },
    created() {
      this.failedRequest = false;
      const { role_name } = JSON.parse(localStorage.getItem('user'))
      if (role_name !== 'Satker') this.$router.push('/user/home')
    }
  };
  </script>
  
  <style>
  
  </style>